<template>
	<div class="install-success">
		
		<div class="success-tip">
			<div class="icon">
				<i class="el-icon-success"></i>
			</div>
			<div class="txt">
				<div class="txt1">企微助手安装成功了</div>
				<div class="txt2">快<span style="color: #e7a43c;">联系客服</span>完成最后的配置吧！</div>
			</div>
		</div>
		
		<div class="success-tip1">因企业微信API限制，目前<span style="color: #e7a43c;">配置流程较为复杂</span>，若自行配置有可能<span style="color: #e7a43c;">会配置失败</span>，请联系专属客服，我们会帮你完成配置</div>
		
		<img class="rwm" src="@/assets/img/20210719171848.jpg"></img>
		<div class="rwm-tip">
			<div>打开微信扫一扫，添加专属客服</div>
			<div style="color: #e7a43c;">一对一帮你完成配置</div>
		</div>
		
		<div class="iCan"><div @click="handleToSet">我要自己配置（不推荐）</div></div>
	</div>
</template>

<script>

	export default {
		components: {},
		data() {
			return {
			}
		},
		beforeMount() {
		},
		methods: {
			handleToSet(){
				this.$emit('next')
			}
		}
	}
</script>

<style lang="less" scoped>
	.install-success {
		padding: 40px 0;
		
		display: flex;
		flex-direction: column;
		align-items: center;
		
		.success-tip{
			display: flex;
			align-items: center;
			
			.icon{
				flex: 0 0 auto;
				font-size: 50px;
				color: #409EFF;
			}
			.txt{
				flex: 1 1 auto;
				margin-left: 10px;
				
				.txt1{
					font-size: 18px;
				}
				.txt2{
					font-size: 14px;
					margin-top: 5px;
				}
			}
		}
		
		.success-tip1{
			padding: 5px 10px;
			height: 20px;
			line-height: 20px;
			color: #666;
			background-color: #fdf7ed;
			font-size: 12px;
			box-sizing: content-box;
			margin-top: 20px;
		}
		
		.rwm{
			display: block;
			width: 250px;
			margin-top: 30px;
		}
		
		.rwm-tip{
			font-size: 14px;
			color: #666;
			text-align: center;
			margin-top: 10px;
			line-height: 22px;
		}
		
		.iCan{
			width: 100%;
			display: flex;
			justify-content: flex-end;
			margin-top: 40px;
			font-size: 12px;
			font-weight: bold;
			color: #999;
			cursor: pointer;
		}
	}
</style>
