<template>
	<div class="self-install">
		<div class="self-title">{{stepindex}}、授权企业通讯录<div class="line"></div>
		</div>

		<div class="step-tip">
			(1) 进入企业微信后台，依次点击应用管理→应用→第三方→点击企业店助手，修改应用范围
		</div>
		<img class="detail-img" src="https://cdn.dkycn.cn/melyshop/image/20210802-80e065f9-e3cc-49e3-838c-7d128f77053d.png"></img>
		<div class="step-tip">
			(2) 进入企业微信后台，依次点击管理工具→通讯录同步→开启API接口同步，获取Secret并配置
			<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccnazDBaTAJoVYDEkc3RylARf"
				target="_blank">如何获取Secret?</el-link>
		</div>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" style="margin-top: 20px;">
			<el-form-item label="Secret" prop="Secret">
				<el-input v-model="ruleForm.Secret" style="max-width: 400px;" placeholder="请填写通讯录Secret"></el-input>
			</el-form-item>
		</el-form>
		<div class="step-tip">
			(3) 分别复制下方URL、Token、EncodingAESKey，进入企业微信后台，管理工具→通讯录同步→开启API接口同步→设置接收事件服务器，复制到相应区域。
			<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccnOcREmLjugzOiKlBV0qK5ph"
				target="_blank">点击查看配置说明</el-link>
		</div>

		<div class="set-info">
			<div class="left">
				<div class="tit">URL：</div>
				<div class="txt">{{result.AddressBookConfigUrl}}</div>
			</div>
			<div class="copy">
				<el-button type="primary" size="mini" v-clipboard:copy="result.AddressBookConfigUrl" v-clipboard:success="onCopy" v-clipboard:error="onError">复制</el-button>
			</div>
		</div>
		<div class="set-info">
			<div class="left">
				<div class="tit">Token：</div>
				<div class="txt">{{result.Token}}</div>
			</div>
			<div class="copy">
				<el-button type="primary" size="mini" v-clipboard:copy="result.Token" v-clipboard:success="onCopy" v-clipboard:error="onError">复制</el-button>
			</div>
		</div>
		<div class="set-info">
			<div class="left">
				<div class="tit">EncodingAESKey：</div>
				<div class="txt">{{result.EncodingAESKey}}</div>
			</div>
			<div class="copy">
				<el-button type="primary" size="mini" v-clipboard:copy="result.EncodingAESKey" v-clipboard:success="onCopy" v-clipboard:error="onError">复制</el-button>
			</div>
		</div>

	</div>
</template>

<script>
	
	import {
		qyweixinsuiteauthinfo
	} from '@/api/sv1.0.0.js';
	
	export default {
		props:{
			stepindex:{
				type:Number,
				default:0
			}
		},
		components: {},
		data() {
			return {
				ruleForm: {
					Secret: ''
				},
				rules: {
					Secret: [{
						required: true,
						message: '请完成配置',
						trigger: 'blur'
					}]
				},
				result: {}
			}
		},
		beforeMount() {
			this.getData();
		},
		methods: {
			async getData(){
				try{
					const res = await qyweixinsuiteauthinfo()
					this.result = res.Result;
					this.ruleForm.Secret = res.Result.AddressBookSecret;
				}catch(e){
					//TODO handle the exception
				}
			},
			onCopy() {
				this.$message({
					message: "复制成功！",
					type: 'success'
				});
			
			},
			onError() {
				this.$message({
					message: "复制失败！",
					type: 'error'
				});
			},
			validForm() {
				return new Promise((resolve, reject)=>{
					this.$refs['ruleForm'].validate((valid) => {
						if (valid) {
							resolve(this.ruleForm.Secret)
						} else {
							reject();
						}
					});
				})
				
			}
		}
	}
</script>

<style lang="less" scoped>
	.self-install {

		padding-top: 25px;

		.self-title {
			font-size: 15px;
			position: relative;
			padding-bottom: 10px;
			border-bottom: 1px solid #ddd;

			.line {
				position: absolute;
				bottom: 0;
				width: 70px;
				height: 3px;
				background-color: #409EFF;
				border-radius: 2px;
			}
		}

		.step-tip {
			padding: 8px 10px;
			font-size: 14px;
			border: 1px solid #409EFF;
			line-height: 22px;
			min-height: 22px;
			background-color: #fbfdff;
			margin-top: 20px;
			max-width: 900px;
		}

		.detail-img {
			display: block;
			width: 100%;
			max-width: 900px;
			margin-top: 20px;
		}

		.set-info {
			padding: 10px 20px;
			background-color: #f9f9f9;
			border: 1px solid #eee;
			margin-top: 20px;

			display: flex;
			overflow: hidden;

			.left {
				flex: 1 1 auto;
				overflow: hidden;
				color: #666;

				.tit {
					font-size: 15px;
					margin-bottom: 10px;
				}

				.txt {
					font-size: 14px;
					color: #333;
					word-break: break-all;
				}
			}

			.copy {
				flex: 0 0 auto;
				margin-left: 40px;
				margin-top: 10px;
			}
		}
	}
</style>
