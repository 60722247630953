<template>
	<div class="qy-wechat">
		
		<div class="qy-wechat-wraper">
			<el-card class="left-card" v-if="isBind">
				<div class="title">企业微信</div>		
				<div class="content">
					<div class="content-title">已授权企业</div>
					<el-form ref="form" label-width="160px" class="form-wraper">
						<el-form-item label="企业logo:">
							<img style="max-width: 200px;" :src="result.CorpSquareLogoUrl" />
						</el-form-item>
						<el-form-item label="企业简称:">
							<div>{{result.CorpName}}</div>
						</el-form-item>
						<el-form-item label="企业名称:">
							<el-button type="danger" size="mini" plain round v-if="!result.CorpFullName">未认证</el-button>
							<div v-else>
								{{result.CorpFullName}} <el-button type="success" plain round style="margin-left: 10px;">已认证</el-button>
							</div>
							
						</el-form-item>
						<el-form-item label="员工规模:">
							<div>{{result.CorpScale || '--'}}</div>
						</el-form-item>
						<el-form-item label="企业地址:">
							<div>{{result.Location}}</div>
						</el-form-item>
						<!-- <el-form-item label="自建应用Secret:">
							<div v-if="!isBindMiniprogram">
								<el-input style="width: 350px;margin-right: 10px;" v-model="result.SelfBuiltSecret" placeholder="请将企业微信中获取的Secret复制粘贴到此处"></el-input>
								<el-button type="primary" plain @click="saveSecret(2, result.SelfBuiltSecret)">保存</el-button>
							</div>
							<div v-else>
								授权绑定员工端使用的微信小程序，并配置在企业微信工作台供员工登录使用
								<el-button style="margin-left: 10px;" type="primary" plain @click="handleJumpToAuth">去授权</el-button>
							</div>
							<div class="form-item-tip">
								<div>配置自建应用Secret,用于员工登录小程序识别员工身份，若未配置</div>
								<div>
									将无法识别
									<el-link type="primary" style="margin-left: 10px;font-size: 12px;" href="https://jusnn6k8al.feishu.cn/docs/doccnC1cQF1jR0sh8f9cw2Ar2Gb" target="_blank">如何获取自建应用Secret?</el-link>
								</div>
							</div>
						</el-form-item> -->
					</el-form>
				</div>
				
			</el-card>
			
			<el-card class="left-card" v-if="!isBind">
				<div class="title">授权企业微信</div>
				
				<!-- 未安装 -->
				<no-auth v-if="step===0||step===1" @next="handleNextStep"></no-auth>
				
				<!-- 已安装 -->
				<install-success v-if="step===2" @next="handleNextStep"></install-success>
				
				<!-- 自己配置 第1步 -->
				<installStep0 ref="installStep0" :stepindex="stepindex" v-if="step===3"></installStep0>
				
				<!-- 自己配置 第2步 -->
				<installStep1 ref="installStep1" :stepindex="stepindex" v-if="step===4"></installStep1>
				
				<!-- 自己配置 第3步 -->
				<installStep2 ref="installStep2" :stepindex="stepindex" v-if="step===5&&result.IsQyWeixinExternalContactInternalMall"></installStep2>
				
				<!-- 自己配置 第4步 -->
				<!-- <installStep3 ref="installStep3" :stepindex="stepindex" v-if="step===6"></installStep3> -->
				
				<!-- 自己配置 第5步 -->
				<installStep4 ref="installStep4" :stepindex="stepindex" v-if="step===7"></installStep4>
				
				<!-- 自己配置 第6步 -->
				<create-app ref="createApp" :stepindex="stepindex" v-if="step===8"></create-app>
				
				<!-- 自己配置 第7步 -->
				<installStep5 ref="installStep5" :result="result" :stepindex="stepindex" v-if="step===9" @finished="handleOver"></installStep5>
				
			</el-card>
			
			
			
			<el-card class="right-card" v-if="!isBind && [3,4,5,6, 7, 8].indexOf(step) > -1">
				<div class="right-tips">
					<div class="tips">
						<div>【提示】因企业微信API限制，目前<span style="color: #e7a43c;">配置流程较为复杂</span>，</div>
						<div>若自行配置可能<span style="color: #e7a43c;">会配置失败</span>，</div>
						<div>请联系专属客服，我们会帮你配置</div>
					</div>
					<img class="rwm" src="@/assets/img/20210719171848.jpg" />
					<div class="rwm-tip">
						<div>微信扫码，添加专属客服</div>
						<div style="color: #e7a43c;">一对一帮你完成配置</div>
					</div>
				</div>
			</el-card>
		</div>
		
		
		<div class="footer">
			<el-button style="width: 110px;margin-right: 20px;" @click="laststep" v-if="step==6||step==5||step==7||step==8">上一步</el-button>
			<el-button type="primary" style="width: 300px;" @click="handleNext" v-if="step==3">确认企业信息，进入下一步</el-button>
			<el-button type="primary" style="width: 300px;" @click="handleNext" v-if="step==4||step==5||step==6||step==7">我已配置完成，进入下一步</el-button>
			<el-button type="primary" style="width: 300px;" @click="handleFinished" v-if="step==8">所有配置完成，开始同步数据</el-button>
		</div>
		
		<el-dialog :visible.sync="step3Tip" title="提示" width="600px">
			<div class="dialog-tip-wraper">
				<div class="icon"><i class="el-icon-warning" style="color: #E6A23C;"></i></div>
				<div class="dialog-tip-content" v-if="errorCode===40000001">
					企业信息验证失败。<span style="color:#F56C6C">企业ID：{{submitData}}</span>未安装第三方应用企店助手，请填写正确的企业ID。如果在安装过程中遇到疑问，你可以点击下方按钮联系企店专属客服。
				</div>
				<div class="dialog-tip-content" v-if="errorCode===40000002">
					企业信息验证失败。<span style="color:#F56C6C">企业ID：{{submitData}}已同步其他商城数据（商城：{{mallname}}）</span>，请使用其他企业微信安装企店助手应用。如果在安装过程中遇到疑问，你可以点击下方按钮联系企店专属客服。
				</div>
				<div class="dialog-tip-content" v-if="errorCode===0">
					企业信息验证成功。<span style="color:#F56C6C">请确认与商城同步数据的企业为：{{mallname}}</span>。如果在安装过程中遇到疑问，你可以点击下方按钮联系企店专属客服。
				</div>
			</div>
			<div class="dialog-footer" slot="footer">
				<el-button size="mini" @click="kfvisible=true">联系客服</el-button>
				<el-button type="primary" size="mini" @click="step3Tip=false" v-if="errorCode===40000001">重新填写</el-button>
				<el-button type="primary" size="mini" @click="handleToAuth" v-if="errorCode===40000002">更换企业微信并重新安装应用</el-button>
				<el-button type="primary" size="mini" @click="handleNextStep" v-if="errorCode===0">确认企业，继续配置</el-button>
			</div>
		</el-dialog>
		
		<el-dialog :visible.sync="step4Tip" title="提示" width="500px">
			<div class="dialog-tip-wraper">
				<div class="icon"><i class="el-icon-warning" style="color: #E6A23C;"></i></div>
				<div class="dialog-tip-content">
					请确认在企业微信后台→客户联系当中，完成接收事件服务器的配置，如果未配置，<span style="color:#F56C6C">客户数据、客户标签等均无法同步</span>
				</div>
			</div>
			<div class="dialog-footer" slot="footer">
				<el-button size="mini" @click="step4Tip=false">再检查一下</el-button>
				<el-button type="primary" size="mini" @click="step4Submit">确认完成配置</el-button>
			</div>
		</el-dialog>
		
		<el-dialog :visible.sync="step5Tip" title="提示" width="500px">
			<div class="dialog-tip-wraper">
				<div class="icon"><i class="el-icon-warning" style="color: #E6A23C;"></i></div>
				<div class="dialog-tip-content">
					请确认在企业微信后台→应用管理→通讯录同步当中，完成接收事件服务器的配置，如果未配置，<span style="color:#F56C6C">员工数据无法同步</span>
				</div>
			</div>
			<div class="dialog-footer" slot="footer">
				<el-button size="mini" @click="step5Tip=false">再检查一下</el-button>
				<el-button type="primary" size="mini" @click="step5Submit">确认完成配置</el-button>
			</div>
		</el-dialog>
		
		<el-dialog :visible.sync="step6Tip" title="提示" width="500px">
			<div class="dialog-tip-wraper">
				<div class="icon"><i class="el-icon-warning" style="color: #E6A23C;"></i></div>
				<div class="dialog-tip-content">
					请确认在企业微信后台→客户联系当中，已经将公众号/小程序：<span style="color:#F56C6C">{{nickName}}</span>与企业微信绑定，若未绑定，或绑定的公众号/小程序不是<span style="color:#F56C6C">{{nickName}}，客户数据无法互通与同步</span>
				</div>
			</div>
			<div class="dialog-footer" slot="footer">
				<el-button size="mini" @click="step6Tip=false">再检查一下</el-button>
				<el-button type="primary" size="mini" @click="step6Submit">确认完成配置</el-button>
			</div>
		</el-dialog>
		
		<!-- 客服二维码 -->
		<el-dialog
		  title="提示"
		  :visible.sync="kfvisible"
		  width="480px">
		  
		  <div class="dialog-tip">
			  <div class="icon">
				  <i class="el-icon-warning"></i>
			  </div>
			  <div class="txt">使用微信扫描下方二维码，添加专属客服，<span style="color: #e7a43c;">我们会帮你完成配置</span></div>
		  </div>
		  
		  <img src="@/assets/img/20210719171848.jpg" class="dialog-rwm" />
		  
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="kfvisible = false" size="mini">我知道了</el-button>
		  </span>
		</el-dialog>
		
		<el-dialog
		  title="提示"
		  :visible.sync="kfvisible1"
		  width="480px">
		  
		  <div class="dialog-tip">
			  <div class="icon">
				  <i class="el-icon-warning"></i>
			  </div>
			  <div class="txt">授权或注册过程中，若遇到疑问，使用微信扫描下方二维码，<span style="color: #e7a43c;">添加专属客服</span>，一对一为你答疑解惑</div>
		  </div>
		  
		  <img src="@/assets/img/20210719171848.jpg" class="dialog-rwm" />
		  
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="kfvisible1 = false" size="mini">我知道了</el-button>
		  </span>
		</el-dialog>
		<el-dialog title="提示" :visible.sync="errormsg.errorShow" width="480px">
		  <div class="dialog-tip">
			  <!-- <div class="icon">
				  <i class="el-icon-warning"></i>
			  </div> -->
			  <div class="txt">
					{{errormsg.content}}
					<!-- 授权或注册过程中，若遇到疑问，使用微信扫描下方二维码，<span style="color: #e7a43c;">添加专属客服</span>，一对一为你答疑解惑 -->
				</div>
		  </div>
		  
		  <img src="@/assets/img/20210719171848.jpg" class="dialog-rwm" />
		  
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="kfvisible1 = false" size="mini">我知道了</el-button>
		  </span>
		</el-dialog>
	</div>
	
</template>

<script>
	
	import noAuth from './components/noAuth.vue';
	import installSuccess from './components/installSuccess.vue'
	import installStep0 from './components/installStep0.vue'
	import installStep1 from './components/installStep1.vue'
	import installStep2 from './components/installStep2.vue'
	import installStep3 from './components/installStep3.vue'
	import installStep4 from './components/installStep4.vue'
	import installStep5 from './components/installStep5.vue'
	import createApp from './components/createApp.vue';
	
	import {
		qyweixinsuiteauthinfo,
		configstepsave,
		qyweixinsecretsave,
		setqyweixinsidebarconfig,
		saveCorpId,
		qyWeiXinPreAuthCodeLink,
		qyweixinvaliteOpenIsNeedCert
	} from '@/api/sv1.0.0.js';
	
	import {
		wxPublicPlatFromInfo,
		mallFreeVersionConfigInfo
	} from '@/api/goods.js';
	
	import apiList from '@/api/other';
	
	export default {
		components: {
			noAuth,
			installSuccess,
			installStep0,
			installStep1,
			installStep2,
			installStep3,
			installStep4,
			installStep5,
			createApp
		},
		data() {
			return {
				isBind: true,
				step: 1,
				stepindex:1,
				result: {},
				isBindMiniprogram: false,
				kfvisible: false,
				kfvisible1:false,
				
				step3Tip: false,
				step4Tip: false,
				step5Tip: false,
				step6Tip: false,
				errorCode: 0,
				submitData: '',
				mallname:'',
				
				nickName: '',
				url: '',
				errormsg:{
					errorShow:false,
					content:'代注册开放平台未认证，操作失败。请扫描下方客服二维码为协助您完成认证'
				}
			}
		},
		beforeMount() {
			this.getData();
			this.getUrl();
			// this.step = 7
			// this.isBind = false
		},
		methods: {
			getstepindex(){
				let num = this.result.ConfigStep
				let index = num-2
				//第四步没有了 所以step>6的index多减1
				if(num==0||num==1){
					index = 1
				}else if(num>=7){
					//如果IsQyWeixinExternalContactInternalMall 不为true 第三步不展示 后面的index再多减1
					index = this.result.IsQyWeixinExternalContactInternalMall?(num-3):(num-4)
				}
				// console.log(num)
				this.stepindex = index

			},
			laststep(){
				if(this.stepindex>1){
					this.stepindex--
				}
				if(this.step == 7){
					this.step = 5
					if(!this.result.IsQyWeixinExternalContactInternalMall){
						this.step = 4
					}
				}else{
					this.step--
				}
			},
			async getUrl() {
				const res = await qyWeiXinPreAuthCodeLink();
				this.url = res.Result.PreAuthLinkUrl;
			},
			async getData(){
				try{
					const res = await qyweixinsuiteauthinfo();
					// 改动
					// res.Result.ConfigStep = 9;	
					// res.Result.IsQyWeixinExternalContactInternalMall = false

					this.step = res.Result.ConfigStep || 1;
					this.isBind = res.Result.ConfigStep === 10;
					this.result = res.Result;
					this.getstepindex()
					this.getBindMiniprogramInfo();
					this.getAuthUrl();
				}catch(e){
					//TODO handle the exception
				}
			},
			async getBindMiniprogramInfo(){
				try {
					let result = await apiList.mcroProgramList();
					this.isBindMiniprogram = false;
					result.map(item=>{
						if (item.MicroProgramType === 1 && item.MicroProgramData && MicroProgramData.Id){
							this.isBindMiniprogram = true;
						}
					})
				} catch (e) {}
			},
			async getAuthUrl(){
				let result = await mallFreeVersionConfigInfo()
				this.authUrl = result.Result.ManagerWxAppAuthUrl;
			},
			handleJumpToAuth(){
				window.open(this.authUrl)
			},
			handleOver(){
				this.handleNextStep(()=>{
					this.getData();
				});
			},
			handleNext(){
				
				if (this.step == 3){
					this.$refs['installStep0'].validForm().then(res=>{
						// this.step4Tip = true;
						this.submitData = res;
						this.saveCropId();
					})
				}
				
				if (this.step == 4){
					this.handleNextStep();
					return;
				}
				
				if (this.step == 5){
					this.$refs['installStep2'].validForm().then(res=>{
						this.step4Tip = true;
						this.submitData = res;
					})
				}
				
				if (this.step == 6){
					this.$refs['installStep3'].validForm().then(res=>{
						this.step5Tip = true;
						this.submitData = res;
					})
				}
				
				if (this.step == 7){
					let msg = this.$refs.installStep4.returnMsg()
					if(!msg.miniProgramAppId&&!msg.publicAccountAppId){
						this.$message.error('请先前往绑定公众号/小程序')
						return
					}
					this.nickName = msg.nickName
					// console.log(this.nickName)
					// console.log(1)
					// this.getWxPublicPlatFromInfo().then(res=>{
						this.step6Tip = true;
					// })
				}
				
			},
			step4Submit(){
				this.saveSecret(4, this.submitData).then(res=>{
					this.handleNextStep();
				})
			},
			step5Submit(){
				this.saveSecret(3, this.submitData).then(res=>{
					this.handleNextStep();
				})
			},
			step6Submit(){
				this.handleNextStep();
			},
			async handleFinished(){
				try{
					let res = await qyweixinvaliteOpenIsNeedCert({noError:true})
					if(res.IsSuccess){
						this.$refs['createApp'].validForm().then(res=>{
							// this.step7Tip = true;
							this.submitData = res;
							this.saveCreateApp().then(res=>{
								this.handleNextStep();
							})
						})
					}else if(res.ErrorCode==610015){
						this.errormsg={
							errorShow:true,
							content:'代注册开放平台未认证，操作失败。请扫描下方客服二维码为协助您完成认证'
						}
					}
				}finally{
					//
				}
			},
			saveCreateApp(){
				return new Promise(async (resolve, reject)=>{
					try{
						const res = await setqyweixinsidebarconfig({
							AgentId: this.submitData.AgentId,
							Secret: this.submitData.Secret,
							TestingFile: this.submitData.TestingFile
						})
						this.$message.success('保存成功')
						resolve();
					}catch(e){
						reject();
					}
				})
			},
			saveCropId(){
				return new Promise(async (resolve, reject)=>{
					try{
						let res = await saveCorpId({
							CorpId: this.submitData,
							noError: true
						})
						
						this.errorCode = res.ErrorCode;
						if (res.ErrorCode === 40000001 || res.ErrorCode === 40000002 || res.ErrorCode === 0){
							this.step3Tip = true;
							
							if (res.ErrorCode === 40000002){
								this.mallname = res.Message;
							}else if (res.ErrorCode === 0){
								this.mallname = res.Result.corp_name;
							}else{
								this.mallname = '';
							}
						}else{
							this.$message.error(res.Message)
						}
						resolve();
					}catch(e){
						reject();
					}
				})
			},
			handleToAuth() {
				window.open(this.url);
				this.$confirm('请在新窗口扫码完成授权安装', '提示', {
					confirmButtonText: '我已安装成功',
					cancelButtonText: '安装失败',
					type: 'warning',
					closeOnClickModal: false,
					showClose: false
				}).then(() => {
					this.getAuthInfo((res)=>{
						if (res){
							this.step3Tip = false;
							this.$message.success('安装成功')
						}else{
							this.showErrorMessage();
						}
					})
				}).catch(() => {
					this.getAuthInfo((res)=>{
						if (res){
							this.step3Tip = false;
							this.$message.success('安装成功')
						}else{
							this.showErrorMessage();
						}
					})
					
				});
			},
			showErrorMessage(){
				this.$confirm('安装失败', '提示', {
					confirmButtonText: '重新安装',
					cancelButtonText: '联系客服',
					type: 'warning',
					closeOnClickModal: false,
				}).then(() => {
					this.handleToAuth();
				}).catch(() => {
					this.conncatCustomerService();
				});
			},
			conncatCustomerService(){
				this.kfvisible1 = true;
			},
			handleClose(){
				this.kfvisible1 = false;
			},
			async getAuthInfo(callback){
				try{
					const res = await qyweixinsuiteauthinfo()
					if (res.Result.IsAuthQyWeixinSuite){
						callback(true)
					}else{
						callback(false)
						this.$message.error('您还未安装企微助手')
					}
				}catch(e){
					//TODO handle the exception
				}
			},
			
			//保存secret
			saveSecret(type, secret){
				return new Promise(async (resolve, reject)=>{
					try{
						const res = await qyweixinsecretsave({
							SecretType: type,
							Secret: secret
						})
						this.$message.success('保存成功')
						resolve();
					}catch(e){
						reject();
					}
				})
				
			},
			//验证是否绑定公众号
			getWxPublicPlatFromInfo(){
				return new Promise(async (resolve, reject)=>{
					try{
						const res = await wxPublicPlatFromInfo()
						if (res.Result.AppId){
							this.nickName = res.Result.NickName
							resolve();
						}else{
							this.$message.error('请绑定公众号')
							reject()
						}
					}catch(e){
						reject();
					}
				})
				
			},
			async handleNextStep(callback){
				try{
					
					var index = this.step+1;
					if(this.step == 5||(this.step==4&&!this.result.IsQyWeixinExternalContactInternalMall)){
						index = 7
					}
					const res = await configstepsave({
						ConfigStep: index
					})
					if(res.IsSuccess){
						this.stepindex++
						if(this.step==5||(this.step==4&&!this.result.IsQyWeixinExternalContactInternalMall)){
							this.step = 7
						}else{
							this.step ++;
						}
						
						this.step3Tip = false;
						this.step4Tip = false;
						this.step5Tip = false;
						this.step6Tip = false;

						this.getData()
						callback()
					}
				}catch(e){
					//TODO handle the exception
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	
	.dialog-tip-wraper{
		display: flex;
		
		.icon{
			flex-direction: 0 0 auto;
			font-size: 26px;
			margin-right: 20px;
		}
		.dialog-tip-content{
			flex: 1 1 auto;
			line-height: 26px;
			margin-right: 40px;
		}
	}
	
	.qy-wechat{
		
		
		.qy-wechat-wraper{
			display: flex;
		}
		
		.left-card{
			flex: 1 1 auto;
		}
		.right-card{
			flex: 0 0 auto;
			width: 380px;
			height: 400px;
			margin-left: 20px;
			
			.tips{
				line-height: 22px;
				font-size: 13px;
				text-align: center;
			}
			
			.rwm{
				display: block;
				width: 200px;
				margin: 20px auto 0;
			}
			
			.rwm-tip{
				font-size: 14px;
				color: #666;
				text-align: center;
				margin-top: 10px;
				line-height: 20px;
			}
		}
	}
	.title{
		font-size: 15px;
		font-weight: bold;
	}
	
	.content{
		margin: 30px 0;
		border: 1px solid #ddd;
		
		.content-title{
			height: 40px;
			line-height: 40px;
			background-color: #f5f5f5;
			font-size: 14px;
			color: #666;
			padding-left: 20px;
			margin-bottom: 30px;
		}
		.form-item-tip{
			line-height: 20px;
			color: #999;
			margin-top: 10px;
			font-size: 12px;
		}
	}
	
	.footer{
		// position: fixed;
		// bottom: 0;
		// right: 0;
		// left: 220px;
		text-align: center;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		// background-color: #fff;
		// border-top: 1px solid #ddd;
		margin-top: 50px;
	}
	
	// @media screen and (max-width: 1280px) {
	// 	.footer{
	// 		left: 140px;
	// 	}
	// }
	
	.dialog-tip{
		display: flex;
		
		.icon{
			flex: 0 0 auto;
			font-size: 16px;
			color: #e7a43c;
			line-height: 22px;
			margin-top: 1px;
			margin-right: 10px;
		}
		.txt{
			flex: 1 1 auto;
			font-size: 14px;
			line-height: 22px;
		}
		
	}
	.dialog-rwm{
		display: block;
		width: 300px;
		margin: 30px auto 0;
	}
</style>
