<template>
	<div class="no-auth">
		<div class="logo-wraper">
			<div class="logo">
				<img src="@/assets/img/20210719171854.png">
				<div>企店助手</div>
			</div>
			<div class="arrow">
				<img src="@/assets/img/ArrowSwap-1.png">
			</div>
			<div class="logo">
				<img style="width: 70px;" src="@/assets/img/20210717174047.jpg">
				<div style="margin-top: 9px;">企业微信</div>
			</div>
		</div>

		<div style="text-align: center;margin-top: 60px;">
			<el-button type="primary" style="width: 300px;" @click="handleToAuth">授权并安装企店助手</el-button>
		</div>
		<div style="text-align: center;margin-top: 10px;">
			<el-button type="primary" style="width: 300px;" plain @click="handleToSign">我还没有企业微信，现在去注册</el-button>
		</div>

		<div class="auth-tip">
			授权或注册过程中，若遇到疑问，请
			<el-popover width="200" trigger="hover">
				<div class="service-info" style="text-align: center;">
					<div style="font-size: 13px;font-weight: bold;margin-bottom: 5px;">添加专属客服</div>
					<div style="font-size: 12px;color: #666;margin-bottom: 5px;">一对一为您答疑解惑</div>
					<img style="width: 140px;" src="@/assets/img/20210719171848.jpg"></img>
				</div>
				<el-link slot="reference" type="primary" style="margin-right: 20px;">联系客服</el-link>
			</el-popover>
		</div>
		
		<el-dialog
		  title="提示"
		  :visible.sync="visible"
		  width="480px"
		  :before-close="handleClose">
		  
		  <div class="dialog-tip">
			  <div class="icon">
				  <i class="el-icon-warning"></i>
			  </div>
			  <div class="txt">授权或注册过程中，若遇到疑问，使用微信扫描下方二维码，<span style="color: #e7a43c;">添加专属客服</span>，一对一为你答疑解惑</div>
		  </div>
		  
		  <img src="@/assets/img/20210719171848.jpg" class="dialog-rwm"></img>
		  
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="visible = false" size="mini">我知道了</el-button>
		  </span>
		</el-dialog>
		
	</div>
</template>

<script>
	import {
		qyWeiXinPreAuthCodeLink,
		qyweixinsuiteauthinfo
	} from '@/api/sv1.0.0.js'

	export default {
		components: {},
		data() {
			return {
				visible: false
			}
		},
		beforeMount() {
			this.getData();
			
			if (this.$route.query.type){
				this.$confirm('请在新窗口扫码完成授权安装', '提示', {
					confirmButtonText: '我已安装成功',
					cancelButtonText: '安装失败',
					type: 'warning',
					closeOnClickModal: false,
					showClose: false
				}).then(() => {
					this.getAuthInfo((res)=>{
						if (res){
							this.$emit('next')
						}
					})
				}).catch(() => {
					this.getAuthInfo((res)=>{
						if (res){
							this.$emit('next')
						}else{
							this.showErrorMessage();
						}
					})
					
				});
			}
		},
		methods: {
			async getData() {
				const res = await qyWeiXinPreAuthCodeLink();
				this.url = res.Result.PreAuthLinkUrl;
			},
			async getAuthInfo(callback){
				try{
					const res = await qyweixinsuiteauthinfo()
					if (res.Result.IsAuthQyWeixinSuite){
						callback(true)
					}else{
						callback(false)
						this.$message.error('您还未安装企微助手')
					}
				}catch(e){
					//TODO handle the exception
				}
			},
			handleToSign() {
				window.open('https://work.weixin.qq.com/wework_admin/register_wx?from=myhome_openApi')
			},
			handleToAuth() {
				window.open(this.url);
				this.$confirm('请在新窗口扫码完成授权安装', '提示', {
					confirmButtonText: '我已安装成功',
					cancelButtonText: '安装失败',
					type: 'warning',
					closeOnClickModal: false,
					showClose: false
				}).then(() => {
					this.getAuthInfo((res)=>{
						if (res){
							this.$emit('next')
						}else{
							this.showErrorMessage();
						}
					})
				}).catch(() => {
					this.getAuthInfo((res)=>{
						if (res){
							this.$emit('next')
						}else{
							this.showErrorMessage();
						}
					})
					
				});
			},
			showErrorMessage(){
				this.$confirm('安装失败', '提示', {
					confirmButtonText: '重新安装',
					cancelButtonText: '联系客服',
					type: 'warning',
					closeOnClickModal: false,
				}).then(() => {
					this.handleToAuth();
				}).catch(() => {
					this.conncatCustomerService();
				});
			},
			conncatCustomerService(){
				this.visible = true;
			},
			handleClose(){
				this.visible = false;
			}
		}
	}
</script>

<style lang="less" scoped>
	.no-auth {
		padding: 80px 0;


		.logo-wraper {
			display: flex;
			justify-content: center;

			.logo {

				display: flex;
				flex-direction: column;
				align-items: center;

				img {
					display: block;
					width: 80px;
				}

				div {
					font-size: 15px;
					color: #999;
				}
			}

			.arrow {
				margin: 15px 10px 0;

				img {
					display: block;
					width: 50px;
				}
			}
		}

		.auth-tip {
			margin-top: 20px;
			font-size: 14px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		
		.dialog-tip{
			display: flex;
			
			.icon{
				flex: 0 0 auto;
				font-size: 16px;
				color: #e7a43c;
				line-height: 22px;
				margin-top: 1px;
				margin-right: 10px;
			}
			.txt{
				flex: 1 1 auto;
				font-size: 14px;
				line-height: 22px;
			}
			
		}
		.dialog-rwm{
			display: block;
			width: 300px;
			margin: 30px auto 0;
		}
	}
</style>
