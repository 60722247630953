<template>
	<div class="self-install">
		<div class="self-title">{{stepindex}}、确认企业信息<div class="line"></div></div>
		
		<div class="step-tip">
			（1）请确认与商城同步数据的企业信息。进入企业微信后台，依次点击我的企业→企业信息，在页面最底部显示的企业ID，复制这串ID并粘贴在下方输入框内
		</div>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" style="margin-top: 20px;">
			<el-form-item label="企业ID:" prop="cropId">
				<el-input v-model="ruleForm.cropId" style="max-width: 400px;" placeholder="请将企业微信后台复制的ID粘贴在此处"></el-input>
			</el-form-item>
		</el-form>
		<img class="detail-img" src="https://cdn.dkycn.cn/images/melyshop/20211231160836.png"></img>
	</div>
</template>

<script>

	export default {
		props:{
			stepindex:{
				type:Number,
				default:0
			}
		},
		components: {},
		data() {
			return {
				ruleForm: {
					cropId: ''
				},
				rules: {
					cropId: [{
						required: true,
						message: '请将企业微信后台复制的ID粘贴在此处',
						trigger: 'blur'
					}]
				},
			}
		},
		beforeMount() {
			this.getData();
		},
		methods: {
			async getData(){
				try{
					const res = await qyweixinsuiteauthinfo()
					this.result = res.Result;
					this.ruleForm.cropId = res.Result.CorpId;
				}catch(e){
					//TODO handle the exception
				}
			},
			validForm() {
				return new Promise((resolve, reject)=>{
					this.$refs['ruleForm'].validate((valid) => {
						if (valid) {
							resolve(this.ruleForm.cropId)
						} else {
							reject();
						}
					});
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.self-install {
		
		padding-top: 25px;
		
		.self-title{
			font-size: 15px;
			position: relative;
			padding-bottom: 10px;
			border-bottom: 1px solid #ddd;
			
			.line{
				position: absolute;
				bottom: 0;
				width: 70px;
				height: 3px;
				background-color: #409EFF;
				border-radius: 2px;
			}
		}
		
		.step-tip{
			padding: 8px 10px;
			font-size: 14px;
			border: 1px solid #409EFF;
			line-height: 22px;
			min-height: 22px;
			background-color: #fbfdff;
			margin-top: 20px;
			max-width: 900px;
		}
		
		.detail-img{
			display: block;
			width: 100%;
			max-width: 900px;
			margin-top: 20px;
		}
	}
</style>
