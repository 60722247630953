<template>
	<div class="self-install">
		<div class="self-title">{{stepindex}}、企业微信绑定公众号/小程序<div class="line"></div>
		</div>

		<div class="step-tip">
			(1) 商城后台绑定公众号/小程序
		</div>
		<div class="bind-info" v-if="!result.AppId&&!miniProgram.AppId">
			商城还未绑定微信公众号/小程序，请先前往绑定 
			<div><el-button type="primary" plain size="mini" style="margin-left: 10px;" @click="handleToBind">前往绑定公众号</el-button></div>
			<div><el-button type="primary" plain size="mini" style="margin-left: 10px;" @click="againauthorization(false)">前往绑定小程序</el-button></div>
		</div>
		<div class="bind-info" v-else style="color: #333;">
			商城已绑定公众号/小程序： 
			<div class="flex flex-align-center" v-if="result.AppId">
				<div><img :src="result.HeadUrl"></img></div> {{result.NickName}}
			</div>
			<div class="flex flex-align-center" v-else>
				<div><img :src="miniProgram.HeadUrl"></img></div> {{miniProgram.NickName}}
			</div>
		</div>
		
		<div class="step-tip">
			(2) 进入企业微信后台，依次点击客户联系->客户，展开API，微信开发者ID，点击【绑定】，绑定时，选择商城后台已经授权的{{result.AppId?'公众号':(miniProgram.AppId?'小程序':'公众号/小程序')}}：
			<span style="color: #e7a43c;">{{result.NickName||miniProgram.NickName}}</span>进行授权绑定
		</div>
		
		<img class="detail-img" src="https://cdn.dkycn.cn/melyshop/image/20210803-88db5fcd-b2a1-4030-8519-16cc18739326.png"></img>
		

		

		<el-dialog title="提示" :visible.sync="dialogFormVisible" width="600px">
			<div style="line-height:18px">
				重新授权不可更换另一个小程序授权绑定，请选择现在已经绑定的小程序：<span
					style="color:#F56C6C">{{proTitle.title}}</span>。若你需要更换小程序授权绑定，请点击下方[联系客服]协助操作。
			</div>
			<div style="margin-top:40px;text-align:right">
				<el-button @click="dialogFormVisible = false">关闭</el-button>
				<!-- <el-popover	placement="bottom"	width="200"	trigger="hover" style="margin:0px 15px">
					<div>

					</div> -->
				<!-- slot="reference" -->
				<el-button type="primary" plain @click="serviceshow = true">联系客服</el-button>
				<!-- </el-popover> -->
				<el-button type="primary" @click="continueto">继续授权</el-button>
			</div>
		</el-dialog>
		<!-- 客服 -->
		<el-dialog title="提示" :visible.sync="serviceshow" width="400px">
			<div style="line-height:18px">
				授权或注册过程中，若遇到问题，使用微信扫描下方二维码，<span style="color:#E6A23C">添加专属客服</span>，一对一为您解答疑惑
			</div>
			<div style="width:100%;text-align:center">
				<img src="@/assets/img/20210719171848.jpg" style="width:150px;height:150px;margin-top:20px" alt="">
			</div>
			<div style="margin-top:20px;text-align:right">
				<el-button size="mini" @click="serviceshow = false">我知道了</el-button>
			</div>
		</el-dialog>
		<!-- 缺少权限76 -->
		<el-dialog title="提示" :visible.sync="lackPermissionsshow" width="420px" top="40vh" custom-class="msgboxdialog">
			<div class = "flexRow">
				<i class="el-icon-warning" style="color:#E6A23C;font-size:18px"></i>
				<div class = "lackPermissionsfont" style="margin-left:10px">
					版本审核提交失败。商城小程序缺少「小程序违规与交易投诉管理」权限，请点击下方重新授权按钮，勾选该权限并重新授权。
				</div>
			</div>
			<div style="text-align:right;margin-top:20px">
				<el-button @click="lackPermissionsshow=false">取消</el-button>
				<el-button @click="serviceshow=true,lackPermissionsshow=false">联系客服协助</el-button>
				<el-button type="primary" @click="continueto">前往重新授权</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	
	import {
		wxPublicPlatFromInfo,
		mallFreeVersionConfigInfo
	} from '@/api/goods.js';
	import apiList from "@/api/other"
	export default {
		props:{
			stepindex:{
				type:Number,
				default:0
			}
		},
		components: {},
		data() {
			return {
				ruleForm: {
					Secret: ''
				},
				rules: {
					Secret: [{
						required: true,
						message: '请完成配置',
						trigger: 'blur'
					}]
				},
				result: {},
				miniProgram:{},
				dialogFormVisible:false,
				data:{},
				lackPermissionsshow:false,
				proTitle: {
					title: '',
					Id: ''
				},
				serviceshow:false,
			}
		},
		beforeMount() {
			// this.getData();
			// this.getDataList()
		},
		mounted () {
			this.getData();
			this.getDataList()
		},
		methods: {
			returnMsg(){
				return {
					miniProgramAppId:this.miniProgram.AppId,
					publicAccountAppId:this.result.AppId,
					nickName:this.result.NickName||this.miniProgram.NickName
				}
			},
			conncatCustomerService() {
				this.serviceshow = true;
			},
			async checkBind() {
				try {

					let res = await apiList.mcroProgramList();
					// console.log(res)
					var list = res.filter(item => {
						//因为只有商城小程序 重新绑定
						return item.MicroProgramType === 0;
					})

					if (list.length && list[0].MicroProgramData.Id) {
						this.getDataList()
					} else {
						this.bindError();
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			bindError() {
				this.$confirm('授权绑定失败', '提示', {
					confirmButtonText: '重新授权',
					cancelButtonText: '联系客服',
					type: 'warning',
					closeOnClickModal: false,
				}).then(() => {
					this.continueto();
				}).catch(() => {
					this.conncatCustomerService();
				});
			},
			continueto() {
				// console.log(this.data.WxAppAuthUrl)
				window.open(this.data.WxAppAuthUrl)
				this.lackPermissionsshow = false
				this.$confirm('请在新窗口扫码完成授权绑定', '提示', {
					confirmButtonText: '我已绑定成功',
					cancelButtonText: '绑定失败',
					type: 'warning',
					closeOnClickModal: false,
					showClose: false
				}).then(() => {
					// this.dialogFormVisible = false
					this.checkBind();
				}).catch(() => {
					// this.dialogFormVisible = false
					this.checkBind();
				});
			},
			async getData2() {
				let result = await mallFreeVersionConfigInfo()
				this.data = result.Result;
			},
			async againauthorization(notip) {
				// console.log(t)
				try{
					this.proTitle = {
						title: this.miniProgram.Title,
						Id: this.miniProgram.Id
					}
					await this.getData2()
					if(notip){
						return 
					}
					this.continueto()
				}finally{
					//
				}
				// this.dialogFormVisible = true
			},
			async getDataList() {
				try {
					// this.loading = true
					let result = await apiList.mcroProgramList();
					if (!result.length) {
						result = [{
							MicroProgramData: {
								Id: 0
							},
							MicroProgramType: 0
						}, {
							MicroProgramData: {
								Id: 0
							},
							MicroProgramType: 1
						}]
					} else if (result.length === 1) {
						if (result[0].MicroProgramType === 0) {
							result.push({
								MicroProgramData: {
									Id: 0
								},
								MicroProgramType: 1
							})
						} else {
							result = [{
								MicroProgramData: {
									Id: 0
								},
								MicroProgramType: 0
							}].concat(result)
						}
					}
					this.miniProgram = result.find(v=>{
						return v.MicroProgramType==0
					}).MicroProgramData
					// console.log(this.miniProgram)
				} catch (e) {} finally {
					// this.loading = false
				}
			},
			async getData() {
				let result = await wxPublicPlatFromInfo()
				this.result = result.Result
			},
			handleToBind(){
				window.open(this.result.AuthUrl)
			},
			validForm() {
				return new Promise((resolve, reject)=>{
					this.$refs['ruleForm'].validate((valid) => {
						if (valid) {
							resolve(this.ruleForm.Secret)
						} else {
							reject();
						}
					});
				})
				
			}
		}
	}
</script>

<style lang="less" scoped>
	.self-install {

		padding-top: 25px;

		.self-title {
			font-size: 15px;
			position: relative;
			padding-bottom: 10px;
			border-bottom: 1px solid #ddd;

			.line {
				position: absolute;
				bottom: 0;
				width: 70px;
				height: 3px;
				background-color: #409EFF;
				border-radius: 2px;
			}
		}

		.step-tip {
			padding: 8px 10px;
			font-size: 14px;
			border: 1px solid #409EFF;
			line-height: 22px;
			min-height: 22px;
			background-color: #fbfdff;
			margin-top: 20px;
			max-width: 900px;
		}

		.detail-img {
			display: block;
			width: 100%;
			max-width: 900px;
			margin-top: 20px;
		}

		.bind-info{
			padding: 10px;
			border: 1px solid #ddd;
			color: #999;
			font-size: 14px;
			margin-top: 20px;
			
			display: flex;
			align-items: center;
			
			img{
				width: 30px;
				height: 30px;
				border-radius: 100%;
				overflow: hidden;
				margin-right: 5px;
				margin-left: 10px;
			}
		}
	}
</style>
