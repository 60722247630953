<template>
	<div class="create-app">
		<div class="self-title">{{stepindex}}、创建自建应用<div class="line"></div>
		</div>
		<!-- <div class="step-tip">
			<div>
				（1）进入企业微信后台，依次点击应用管理→应用，自建应用区域，点击【创建应用】，并填写以下应用信息：
				<el-link type="primary" style="line-height: 20px;" @click="handleHelp(0)">点击查看创建教程</el-link>
			</div>
			<div style="margin-left: 34px;">● 应用Logo：请
				<el-link type="primary" href="/20210811-dac8af19-f344-476f-b0f5-243c23e38410.png"  download>点击此处下载</el-link>
				，并上传
			</div>
			<div style="margin-left: 34px;">● 应用名称请填写：<span style="font-weight: bold;font-size: 14px;color: #409EFF;">企店员工助手</span>
			</div>
			<div style="margin-left: 34px;">● 应用介绍不用填写</div>
			<div style="margin-left: 34px;">● 设置可见范围：选择部门或成员</div>
		</div> -->
	
		<div class="step-tip">
			（1）使用企业微信扫描下方二维码，安装企店员工助手。扫描完成后，在手机界面上确认授权
		</div>
		
		<div class="step-title">·使用企业微信扫码</div>
		<div class="step-image">
			<vue-qr v-if="authLink" :text="authLink" :size="150"></vue-qr>
			<!-- <img style="width: 150px;" src="https://cdn.dkycn.cn/images/melyshop/step6-example-1.png"></img> -->
		</div>
		<div class="step-title">·扫码完成后，在手机界面上按照以下步骤确认授权。授权完成后，扫描右方二维码，联系专属客服为您发布员工助手应用</div>
		<div class="step-image">
			<img style="width: 660px;" src="https://cdn.dkycn.cn/images/melyshop/step6-example-2.png"></img>
		</div>
		
		<div class="step-tip">
			（2）授权完成后，将应用（企店员工助手）详情中的AgentId填入到下方输入框中
			<el-link type="primary" style="line-height: 20px;" @click="handleHelp(1)">如何获取AgentId？</el-link>
		</div>
	
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px" style="margin-top: 20px;">
			<el-form-item label="AgentId" prop="AgentId">
				<el-input v-model="ruleForm.AgentId" style="max-width: 400px;" placeholder="请填写应用AgentId"></el-input>
			</el-form-item>
			<!-- <el-form-item label="Secret" prop="Secret">
				<el-input v-model="ruleForm.Secret" style="max-width: 400px;" placeholder="请填写应用Secret"></el-input>
			</el-form-item> -->
		</el-form>
		<div class="step-tip">
			（3）编辑应用可见范围，并扫描右方二维码，联系企店专属客服为您的商城上线应用
			<el-link type="primary" style="line-height: 20px;" @click="handleHelp(4)">点击查看可见范围配置教程</el-link>
		</div>
		<!-- <div class="set-info" style="margin-top:10px">
			<div class="left">
				<div class="tit">应用主页(网页版员工端登录地址)：</div>
				<div class="txt">{{mellkey}}</div>
			</div>
			<div class="copy">
				<el-button type="primary" size="mini" v-clipboard:copy="mellkey"
					v-clipboard:success="onCopy" v-clipboard:error="onError">复制</el-button>
			</div>
		</div>
		<div class="step-tip">
			（4）在应用详情中，【开发者接口】—【网页授权及JS-SDK】模块下，点击【设置可信域名】，复制下方可信域名填写到输入框内，并且上传文件进行域名校验
			<el-link type="primary" style="line-height: 20px;" @click="handleHelp(2)">点击查看校验教程</el-link>
		</div>
	
		<div class="step3-tip">
			● 复制下方可信域名，并在企业微信后台对应输入框中填入
		</div>
		<div class="set-info">
			<div class="left">
				<div class="tit">可信域名：</div>
				<div class="txt">{{result.TrustedDomain}}</div>
			</div>
			<div class="copy">
				<el-button type="primary" size="mini" v-clipboard:copy="result.TrustedDomain"
					v-clipboard:success="onCopy" v-clipboard:error="onError">复制</el-button>
			</div>
		</div>
	
		<div class="step3-tip">
			● 依次点击【申请校验域名】→【下载文件】，文件下载成功后，点击下方【上传】，选择下载文件
		</div>
		<el-form :model="ruleForm1" :rules="rules1" ref="ruleForm1" label-width="90px" style="margin-top: 20px;">
			<el-form-item label="校验文件:" prop="file">
				<div class="upload-wraper">
					<div v-if="ruleForm1.file" style="color: #666;margin-right: 20px;">{{ruleForm1.file}}</div>
					<div>
						<el-upload action="https://wapapi.qidian.shop/verify/upload" :show-file-list="false"
							:on-success="handleSuccess" :before-upload="handleBeforeUpload">
							<el-button type="primary" size="mini" v-if="!ruleForm1.file">上传</el-button>
							<el-button type="primary" size="mini" v-else>重新上传</el-button>
						</el-upload>
					</div>
				</div>
			</el-form-item>
		</el-form>
	
		<div class="step-tip">
			（5）前往客户联系→客户，展开API，可调应用勾选【企店员工助手】
			<el-link type="primary" style="line-height: 20px;" @click="handleHelp(3)">点击查看配置入口</el-link>
		</div> -->
		
		<el-dialog :visible.sync="visible" :title="helpData.title" width="850px" custom-class="bodypadding">
			<img :src="helpData.img" style="display: block;width: 100%;">
		</el-dialog>
	
	</div>
</template>

<script>
	import vueQr from 'vue-qr'
	import {
		qyweixinsidebarconfig,
		setqyweixinsidebarconfig,
		qyWeixinSelfSuiteAuthLink
	} from '@/api/sv1.0.0.js';
	import {
		mapGetters
	} from "vuex"
	export default {
		props:{
			stepindex:{
				type:Number,
				default:0
			}
		},
		computed: {
			...mapGetters(['pcImg'])
		},
		components: {
			vueQr
		},
		data() {
	
			var validateFile = (rule, value, callback) => {
				console.log(value)
				if (value === '') {
					callback(new Error('请上传校验文件'));
				} else {
					callback();
				}
			};
	
			return {
				isBuild: false,
	
				result: {},
	
				ruleForm: {
					AgentId: '',
					Secret: ''
				},
				rules: {
					AgentId: [{
						required: true,
						message: '请填写自建应用【企店员工助手】的AgentId',
						trigger: 'blur'
					}],
					Secret: [{
						required: true,
						message: '请填写自建应用【企店员工助手】的Secret',
						trigger: 'blur'
					}]
				},
	
				ruleForm1: {
					file: ''
				},
				rules1: {
					file: [{
						required: true,
						trigger: 'change',
						validator: validateFile
					}],
				},
				
				
				helpList: [{
					title: '如何创建自建应用？',
					img: 'https://cdn.dkycn.cn/melyshop/image/createApp1.png'
				},{
					title: '如何获取AgentId？',
					img: 'https://cdn.dkycn.cn/images/melyshop/create-app-agentid-example.png'
				},{
					title: '如何校验域名？',
					img: 'https://cdn.dkycn.cn/melyshop/image/createApp4.png'
				},{
					title: '如何配置可调应用？',
					img: 'https://cdn.dkycn.cn/melyshop/image/createApp5.png'
				},{
					title: '如何设置应用【企店员工助手】的可见范围',
					img: 'https://cdn.dkycn.cn/images/melyshop/create-app-cansee-example.png'
				}],
				helpData: {},
				visible: false,
				mellkey:'',
				authLink: ''
			}
		},
		created() {},
		beforeMount() {},
		mounted() {
			this.getId()
			this.getData();
			this.getLink();
		},
		methods: {
			async getLink(){
				try{
					const res = await qyWeixinSelfSuiteAuthLink();
					this.authLink = res.Result.AuthUrl;
				}catch(e){
					//TODO handle the exception
				}
			},
			getId(){
				// var URL = window.location.href;
				// let h = URL.split(".")[0];
				// let domain = h.split("//")[1];
				// var secondParam = ''
				// if (process.env.NODE_ENV == 'development') {
				// 	secondParam = 'xcs'//推手模式
				// } else {
				// 	secondParam = domain
				// }
				let secondParam = window.localStorage.getItem('mlmzMallKey')
				this.mellkey = 'https://wapapi.qidian.shop/sa/#/pages/index/index?mallkey='+secondParam
			},
			async getData() {
				try {
					const res = await qyweixinsidebarconfig();
					if (res.Result.IsFinishConfig) {
						this.isBuild = true;
					} else {
						this.isBuild = false;
					}
					this.result = res.Result;
					this.ruleForm.AgentId = res.Result.AgentId || '';
					this.ruleForm.Secret = res.Result.Secret || '';
	
					if (res.Result.TestingFile) {
						if (res.Result.TestingFile.split('/').length > 1) {
							this.ruleForm1.TestingFile = res.Result.TestingFile.split('/')[1];
						} else {
							this.ruleForm1.TestingFile = res.Result.TestingFile;
						}
					}
	
				} catch (e) {
					//TODO handle the exception
				}
			},
			onCopy() {
				this.$message({
					message: "复制成功！",
					type: 'success'
				});
	
			},
			onError() {
				this.$message({
					message: "复制失败！",
					type: 'error'
				});
			},
			handleHelp(index){
				this.helpData = this.helpList[index];
				this.visible = true;
			},
			handleBeforeUpload(file) {
				this.fileName = file.name;
				const isText = file.type === 'text/plain';
				const isLt1M = file.size / 1024 / 1024 < 1;
	
				if (!isText) {
					this.$message.error('上传文件只能是 txt 格式!');
				}
				if (!isLt1M) {
					this.$message.error('上传文件大小不能超过 1MB!');
				}
				return isText && isLt1M;
			},
			handleSuccess(res) {
				this.ruleForm1.file = this.fileName;
				this.$refs['ruleForm1'].validate((valid) => {
				});
			},
			validForm() {
				return new Promise((resolve, reject)=>{
					this.$refs['ruleForm'].validate((valid) => {
						if (valid) {
							resolve({
								AgentId: this.ruleForm.AgentId,
								// Secret: this.ruleForm.Secret,
								// TestingFile: '/'+this.ruleForm1.file
							})
						} else {
							this.$message.error('请完成配置')
							reject();
						}
					});
					// this.$refs['ruleForm'].validate((valid) => {
					// 	if (valid) {
					// 		this.$refs['ruleForm1'].validate((valid) => {
					// 			if (valid) {
					// 				resolve({
					// 					AgentId: this.ruleForm.AgentId,
					// 					Secret: this.ruleForm.Secret,
					// 					TestingFile: '/'+this.ruleForm1.file
					// 				})
					// 			}else{
					// 				this.$message.error('请完成配置')
					// 				reject();
					// 			}
					// 		});
					// 	}else{
					// 		this.$message.error('请完成配置')
					// 		reject();
					// 	}
					// });
				})
			},
			handleNext() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.$refs['ruleForm1'].validate((valid) => {
							if (valid) {
								this.save();
							}else{
								this.$message.error('请完成配置')
							}
						});
					}else{
						this.$message.error('请完成配置')
					}
				});
			},
			async save(){
				try{
					const res = await setqyweixinsidebarconfig({
						AgentId: this.ruleForm.AgentId,
						Secret: this.ruleForm.Secret,
						TestingFile: '/'+this.ruleForm1.file
					})
					
					this.$message.success('保存成功');
					this.$router.back()
					
				}catch(e){
					//TODO handle the exception
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.create-app {
		
		padding-top: 25px;
		
		.step-title{
			font-size: 14px;
			color: #606266;
			margin-bottom: 10px;
			margin-top: 30px;
			margin-left: 10px;
		}
		.step-image{
			margin-left: 10px;
			margin-bottom: 40px;
			img{
				display: block;
			}
		}
		
		.self-title {
			font-size: 15px;
			position: relative;
			padding-bottom: 10px;
			border-bottom: 1px solid #ddd;
		
			.line {
				position: absolute;
				bottom: 0;
				width: 70px;
				height: 3px;
				background-color: #409EFF;
				border-radius: 2px;
			}
		}
		
		margin-bottom: 150px;
	
		.title {
			font-size: 17px;
			font-weight: bold;
		}
	
		.step-tip {
			padding: 8px 10px;
			font-size: 14px;
			border: 1px solid #409EFF;
			line-height: 22px;
			min-height: 22px;
			background-color: #fbfdff;
			margin-top: 20px;
			max-width: 900px;
			color: #666;
	
			>div {
				margin-top: 10px;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
			}
		}
	
		.set-info {
			padding: 10px 20px;
			background-color: #f9f9f9;
			border: 1px solid #eee;
			margin-left: 15px;
			max-width: 850px;
	
			display: flex;
			overflow: hidden;
	
			.left {
				flex: 1 1 auto;
				overflow: hidden;
				color: #666;
	
				.tit {
					font-size: 15px;
					margin-bottom: 10px;
				}
	
				.txt {
					font-size: 14px;
					color: #333;
					word-break: break-all;
				}
			}
	
			.copy {
				flex: 0 0 auto;
				margin-left: 40px;
				margin-top: 10px;
			}
		}
	
		.step3-tip {
			font-size: 14px;
			margin: 20px 0 10px 15px;
		}
	
		.upload-wraper {
			display: flex;
		}
	
		.footer {
			position: fixed;
			bottom: 0;
			right: 0;
			left: 220px;
			text-align: center;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #fff;
			border-top: 1px solid #ddd;
		}
	
		@media screen and (max-width: 1280px) {
			.footer {
				left: 140px;
			}
		}
	}
</style>
<style>

	.bodypadding .el-dialog__body{
		padding:0px 20px
	}
</style>
