<template>
	<div class="self-install">
		<div class="self-title">{{stepindex}}、数据同步<div class="line"></div></div>
		
		<div class="step-tip">数据同步中...</div>
		<div class="logo-wraper">
			<div class="logo">
				<img src="@/assets/img/20210719171854.png">
				<div>企店助手</div>
			</div>
			<div class="arrow">
				<img src="@/assets/img/tongbu.gif">
			</div>
			<div class="logo">
				<img style="width: 70px;" src="@/assets/img/20210717174047.jpg">
				<div style="margin-top: 9px;">企业微信</div>
			</div>
		</div>
		
		<div class="result-list">
			<div class="result-item">
				<div class="left"><span style="font-weight:bold">· </span> 员工数据同步</div>
				<div class="right" :style="{color: stateList[0]=='已完成'?'#44B449':'#666666'}">{{stateList[0]}}</div>
			</div>
			<div class="result-item">
				<div class="left"><span style="font-weight:bold">· </span> 企业微信标签库数据同步</div>
				<div class="right" :style="{color: stateList[1]=='已完成'?'#44B449':'#666666'}">{{stateList[1]}}</div>
			</div>
			<!-- <div class="result-item">
				<div class="left">3.员工与微信客户好友关系同步</div>
				<div class="right" :style="{color: stateList[2]=='已完成'?'#44B449':'#666666'}">{{stateList[2]}}</div>
			</div> -->
			<div class="result-item" v-if="result.IsQyWeixinExternalContactInternalMall">
				<div class="left"><span style="font-weight:bold">· </span> 企业微信内客户群数据同步</div>
				<div class="right" :style="{color: stateList[3]=='已完成'?'#44B449':'#666666'}">{{stateList[3]}}</div>
			</div>
		</div>
		
		<div style="text-align: center;margin-top: 70px;margin-bottom: 50px;">
			<el-button type="primary" plain style="width: 300px;" v-if="!finished">数据同步中...</el-button>
			<el-button type="primary" style="width: 300px;" v-if="finished" @click="handleFinished">数据同步完成，刷新企业信息</el-button>
		</div>
		
	</div>
</template>

<script>
	
	import {
		qyweixindatasyncresult
	} from '@/api/sv1.0.0.js';
	
	export default {
		props:{
			stepindex:{
				type:Number,
				default:0
			},
			result:{
				type:Object,
				default:()=>{
					return {}
				}
			}
		},
		components: {},
		data() {
			return {
				stateList: [],
				finished: false,
			}
		},
		beforeMount() {
			this.getData();
			
			this.timer = setInterval(()=>{
				this.getData();
			}, 1000)
		},
		beforeDestroy() {
			clearInterval(this.timer)
		},
		methods: {
			async getData(){
				try{
					const res = await qyweixindatasyncresult();
					this.stateList = res.Result;
					
					var list = res.Result.filter(item=>{
						return item == '已完成'
					})
					
					this.finished = list.length == res.Result.length;
					
					if (this.finished){
						clearInterval(this.timer)
					}
				}catch(e){
					//TODO handle the exception
				}
			},
			handleFinished(){
				this.$emit('finished')
			},
			validForm() {
				return new Promise((resolve, reject)=>{
					this.$refs['ruleForm'].validate((valid) => {
						if (valid) {
							resolve(this.ruleForm.Secret)
						} else {
							reject();
						}
					});
				})
				
			}
		}
	}
</script>

<style lang="less" scoped>
	.self-install {

		padding-top: 25px;

		.self-title {
			font-size: 15px;
			position: relative;
			padding-bottom: 10px;
			border-bottom: 1px solid #ddd;

			.line {
				position: absolute;
				bottom: 0;
				width: 70px;
				height: 3px;
				background-color: #409EFF;
				border-radius: 2px;
			}
		}

		.logo-wraper {
			display: flex;
			justify-content: center;
		
			.logo {
		
				display: flex;
				flex-direction: column;
				align-items: center;
		
				img {
					display: block;
					width: 80px;
				}
		
				div {
					font-size: 15px;
					color: #999;
				}
			}
		
			.arrow {
				margin: 30px 20px 0;
		
				img {
					display: block;
					width: 50px;
				}
			}
		}
	
		.step-tip{
			font-size: 16px;
			font-weight: bold;
			padding: 50px 0 20px;
			text-align: center;
		}
		
		.result-list{
			margin-top: 50px;
			
			.result-item{
				display: flex;
				justify-content: space-between;
				font-size: 14px;
				width: 350px;
				margin: 15px auto;
				color: #666;
				
				.right{
					width: 60px;
				}
			}
			
		}
	}
</style>
