<template>
	<div class="self-install">
		<div class="self-title">{{stepindex}}、设置应用范围<div class="line"></div></div>
		
		<div class="step-tip">
			(1) 进入企业微信后台，依次点击应用管理->应用->第三方->点击企店助手，修改应用范围
		</div>
		<img class="detail-img" src="https://cdn.dkycn.cn/melyshop/image/20210802-80e065f9-e3cc-49e3-838c-7d128f77053d.png"></img>
		<!-- <img class="detail-img" src="@/assets/img/20210717192411.png"></img> -->
	</div>
</template>

<script>

	export default {
		props:{
			stepindex:{
				type:Number,
				default:0
			}
		},
		components: {},
		data() {
			return {
			}
		},
		beforeMount() {
		},
		methods: {
			handleToSet(){
				
			}
		}
	}
</script>

<style lang="less" scoped>
	.self-install {
		
		padding-top: 25px;
		
		.self-title{
			font-size: 15px;
			position: relative;
			padding-bottom: 10px;
			border-bottom: 1px solid #ddd;
			
			.line{
				position: absolute;
				bottom: 0;
				width: 70px;
				height: 3px;
				background-color: #409EFF;
				border-radius: 2px;
			}
		}
		
		.step-tip{
			padding: 8px 10px;
			font-size: 14px;
			border: 1px solid #409EFF;
			line-height: 22px;
			min-height: 22px;
			background-color: #fbfdff;
			margin-top: 20px;
			max-width: 900px;
		}
		
		.detail-img{
			display: block;
			width: 100%;
			max-width: 900px;
			margin-top: 20px;
		}
	}
</style>
